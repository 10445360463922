import { AppDispatch } from "../../app/store";
import http from "../../api/instance/axios";
import District from "../../models/District";

export const getSearchDistricts = (region: string, department: string, district: string) => async (dispatch: AppDispatch) => {
    try {
        let request = "District/Search?";
        if (region && region.length > 0)
            request += `region=${region}&`;
        if (department && department.length > 0)
            request += `department=${department}&`;
        if (district && district.length > 0)
            request += `district=${district}`;
        const res = await dispatch(http.newGet(request));
        if (!res || !res.data)
            return [];
        return res.data as District[];
    } catch (error) {
        console.log(error);
        return [];
    }
}

