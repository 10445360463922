import { useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { login, register } from "../features/Users/UserActions";
import { Button, Checkbox, Container, FormControlLabel, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    // const [usernameError, setUsernameError] = useState<string>("");
    // const [passwordError, setPasswordError] = useState<string>("");
    const usernameError = "";
    const passwordError = "";
    const [error, setError] = useState<string>("");

    const dispatch = useAppDispatch();

    const handleLogin = async () => {
        const res = await dispatch(login(username, password, rememberMe));
        if (res !== null && res !== undefined && res.length > 0)
            setError(res);
        else {
            // reload page to updates infos
            window.location.reload();
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5">Login</Typography><br />
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Username"
                                value={username}
                                onChange={(e: any) => setUsername(e.target.value)}
                                error={usernameError !== ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e: any) => setPassword(e.target.value)}
                                error={passwordError !== ""}
                                InputProps={
                                    {
                                        endAdornment:
                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={rememberMe} onChange={(e: any) => setRememberMe(e.target.checked)} color="primary" />}
                                label="Remember me"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                                Login
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ color: "red" }}>{error}</span>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}

const Register = () => {
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    // const [usernameError, setUsernameError] = useState<string>("");
    const usernameError = "";
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
    const [error, setError] = useState<string>("");

    const dispatch = useAppDispatch();

    const checkEmail = () => {
        // Check if email is valid (contains @ and .)
        const regex = /\S+@\S+\.\S+/;
        setEmailError(regex.test(email) ? "" : "Email is not valid");
    }
    const checkPassword = () => {
        // Check if password is valid (Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character)
        if (!/[A-Z]/.test(password))
            setPasswordError("Password must contain at least one uppercase letter");
        else if (!/[a-z]/.test(password))
            setPasswordError("Password must contain at least one lowercase letter");
        else if (!/[0-9]/.test(password))
            setPasswordError("Password must contain at least one number");
        else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
            setPasswordError("Password must contain at least one special character");
        else
            setPasswordError("");
    }
    const checkConfirmPassword = () => {
        // Check if confirm password is valid (Password must match)
        setConfirmPasswordError(password === confirmPassword ? "" : "Password must match");
    }

    const handleRegister = async () => {
        const res = await dispatch(register(username, email, password, confirmPassword));
        setError((res !== null && res !== undefined && res.length > 0) ? res : "");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5">Register</Typography><br />
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Username"
                                value={username}
                                onChange={(e: any) => setUsername(e.target.value)}
                                error={usernameError !== ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e: any) => setEmail(e.target.value)}
                                onBlur={checkEmail}
                                error={emailError !== ""}
                                helperText={emailError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e: any) => setPassword(e.target.value)}
                                onBlur={checkPassword}
                                error={passwordError !== ""}
                                helperText={passwordError}
                                InputProps={
                                    {
                                        endAdornment:
                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e: any) => setConfirmPassword(e.target.value)}
                                onBlur={checkConfirmPassword}
                                error={confirmPasswordError !== ""}
                                helperText={confirmPasswordError}
                                InputProps={
                                    {
                                        endAdornment:
                                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleRegister}>
                                Register
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ color: "red" }}>{error}</span>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}

export { Login, Register };