import { useState} from "react";
import Commune from "../models/Commune";
import { Collapse, Grid } from "@mui/material";
import Weather from "./Weather";
import Offers from "./Offers";
import DiscoverCatering from "./DiscoverCatering";
import CalendarHeadLine from "./CalendarHeadLine";
import PeopleSpace from "./PeopleSpace";
import FilterSearchPage from "./FilterSearchPage";
import DarkMode from "./DarkMode";
import Responsive from "../features/Responsive/Responsive";
import "./styles/board.css";
const Board = ({ commune }: { commune: Commune }) => {
  const [showPeopleSpace, setShowPeopleSpace] = useState(true);

  const togglePeopleSpace = () => {
    setShowPeopleSpace(!showPeopleSpace);
  };

  const offers = [];
  for (const an of commune.Announcements) {
    if (
      an.AnnouncementOffers !== undefined &&
      an.AnnouncementOffers.length > 0
    ) {
      offers.push(...an.AnnouncementOffers);
    }
  }

  console.log(  "offers", commune.Announcements);

  return (
    <Responsive
      desktop={
        <Grid container columnSpacing={3} marginLeft={"1px"}>
          <Grid container >
            <Grid item md={5.5} sx={{position: 'relative',width: '300vw', zIndex: 99}}>
              <FilterSearchPage commune={commune} />
            </Grid>
            <Grid item md={2.6} sx={{position: 'relative',width: '300vw', zIndex: 99}}>
              <DarkMode />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={2} sx={{ marginBottom: ".5rem" }}>
              <Weather communeName={commune.Name} />
            </Grid>
            <Grid
              item
              md={7}
              sx={{ zIndex: 100 }}
            >
              <Offers offers={offers} name={commune.Name} />
            </Grid>
          </Grid>
          <Grid item md={4} >
            <Collapse in={showPeopleSpace}>
              <CalendarHeadLine events={commune.Events} />
            </Collapse>
            <PeopleSpace
              togglePeopleSpace={togglePeopleSpace}
              isToggle={showPeopleSpace}
              commune={commune}
              
            />
          </Grid>
          <Grid item md={8}>
            <DiscoverCatering tourism={commune.Tourism} />
          </Grid>
        </Grid>
      }
      tablet={
        <Grid container rowSpacing={2} spacing={2}>
          <Grid item xs={9} sm={5} sx={{ zIndex: 99 }}>
            <FilterSearchPage commune={commune} />
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            sx={{ zIndex: 101 }}
            className="dark-content"
          >
            <DarkMode />
          </Grid>
          <Grid item xs={12} sm={5} sx={{ zIndex: 100 }}></Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{ zIndex: 100, marginBottom: "2rem", marginTop: "-2rem" }}
          >
            <Weather communeName={commune.Name} />
          </Grid>
          <Grid item xs={12} sm={7.5} sx={{ zIndex: 100 }}>
            <Offers offers={offers} name={commune.Name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Collapse in={showPeopleSpace}>
              <CalendarHeadLine events={commune.Events} />
            </Collapse>
            <PeopleSpace
              togglePeopleSpace={togglePeopleSpace}
              isToggle={showPeopleSpace}
              commune={commune}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DiscoverCatering tourism={commune.Tourism} />
          </Grid>
        </Grid>
      }
      mobile={
        <Grid container>
          <Grid item xs={7} sm={9} md={9} sx={{ zIndex: 99 }}>
            <FilterSearchPage commune={commune} />
          </Grid>
          <Grid item xs={5} sm={3} md={3} sx={{ zIndex: 101 }}>
            <DarkMode />
            <Weather communeName={commune.Name} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ zIndex: 100 }}>
            <Offers offers={offers} name={commune.Name} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Collapse in={showPeopleSpace}>
              <CalendarHeadLine events={commune.Events} />
            </Collapse>
            <PeopleSpace
              togglePeopleSpace={togglePeopleSpace}
              isToggle={showPeopleSpace}
              commune={commune}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <DiscoverCatering tourism={commune.Tourism} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default Board;
