import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Restaurants from "../../models/Restaurants";

interface RestaurantState {
    value: Restaurants[];
}

const initialState: RestaurantState = {
    value: [],
};


export const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState,
    reducers: {
        setRestaurants: (state, action: PayloadAction<Restaurants[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setRestaurants } = restaurantSlice.actions;
export default restaurantSlice.reducer;
