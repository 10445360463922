import { Grid } from "@mui/material";
import "./styles/filter.css";
import { useAppDispatch } from "../app/hooks";
import Commune from "../models/Commune";
import { useState } from "react";
import "./styles/filter-search-page.css";
import useIsMobile from "../features/Responsive/useIsMobile";
import {
  getCommunesInfos,
  getSearchCommunes,
} from "../features/Commune/CommuneActions";
import { getSearchDepartments } from "../features/Department/DepartmentActions";
import { getSearchDistricts } from "../features/District/DistrictActions";
import District from "../models/District";
import { getSearchRegions } from "../features/Region/RegionActions";
import Department from "../models/Department";
import Region from "../models/Region";
import CustomHint from "./customs/CustomHint";

const FilterSearchPage = ({ commune }: { commune: Commune | undefined }) => {
  const [fltrdCom, setFltrdCom] = useState<Commune[]>([]);
  const [fltrdDis, setFltrdDis] = useState<District[]>([]);
  const [fltrdDep, setFltrdDep] = useState<Department[]>([]);
  const [fltrdReg, setFltrdReg] = useState<Region[]>([]);

  const [inputCommune, setInputCommune] = useState<string>(commune?.Name ?? "");
  const [inputDistrict, setInputDistrict] = useState<string>(
    commune?.District?.Name ?? ""
  );
  const [inputDepartment, setInputDepartment] = useState<string>(
    commune?.District?.Department?.Name ?? ""
  );
  const [inputRegion, setInputRegion] = useState<string>(
    commune?.District?.Department?.Region?.Name ?? ""
  );

  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const filterCommunes = (value: string) => {
    if (value !== "") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      dispatch(
        getSearchCommunes(inputRegion, inputDepartment, inputDistrict, value)
      ).then((res) => {
        setFltrdCom(res);
      });
    }
    setInputCommune(value);
  };

  const filterDistricts = (value: string) => {
    if (value !== "") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      dispatch(getSearchDistricts(inputRegion, inputDepartment, value)).then(
        (res) => {
          setFltrdDis(res);
        }
      );
    }
    setInputDistrict(value);
  };

  const filterDepartments = (value: string) => {
    if (value !== "") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      dispatch(getSearchDepartments(inputRegion, value)).then((res) => {
        setFltrdDep(res);
      });
    }
    setInputDepartment(value);
  };

  const filterRegions = (value: string) => {
    if (value !== "") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
      dispatch(getSearchRegions(value)).then((res) => {
        setFltrdReg(res);
      });
    }
    setInputRegion(value);
  };

  const setInfos = (
    region: string,
    department: string,
    district: string,
    commune: string
  ) => {
    setInputRegion(region);
    setInputDepartment(department);
    setInputDistrict(district);
    setInputCommune(commune);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} sx={{ marginLeft: "1rem" }}>
        <div className={isMobile ? "mobile-input-title" : "input-title"}style={{position: 'sticky',display:'flex',flexWrap: 'nowrap', left: 0}}>
          <CustomHint
            value={inputCommune}
            options={fltrdCom.map((c) => c.Name)}
            inputClass="input-search-commune search-commune"
            placeholder="Rechercher une commune"
            onChange={(value: string) => filterCommunes(value)}
            onFocus={() => {
              if (commune!==undefined) setInfos("", "", "", inputCommune);
            }}
            onBlur={() => {
              if (commune === undefined) return;
              setInfos(
                commune.District?.Department?.Region?.Name ?? "",
                commune.District?.Department?.Name ?? "",
                commune.District?.Name ?? "",
                commune.Name ?? ""
              );
              filterCommunes(commune.Name ?? "");
            }}
            onFill={(value: string) => {
              const com = fltrdCom.find((c) => c.Name === value);
              setInfos(
                com?.District?.Department?.Region?.Name ?? "",
                com?.District?.Department?.Name ?? "",
                com?.District?.Name ?? "",
                value
              );
              if (com !== undefined) dispatch(getCommunesInfos([com.Id], true));
            }}
          />
        </div>
        <div className="input-title-district">
          <Grid container spacing={1} rowSpacing={1}>
            {/* <LockIcon
              sx={{ color: "#c3802b", marginTop: ".5rem", fontSize: "1.1rem" }}
            /> */}
            &nbsp;
            <CustomHint
              value={inputDistrict}
              options={fltrdDis.map((d) => d.Name)}
              inputClass="input-search-district"
              placeholder="district"
              onChange={(value: string) => filterDistricts(value)}
              onFocus={() => {
                if (commune !== undefined) setInfos("", "", "", inputCommune);
              }}
              onBlur={() => {
                if (commune === undefined) return;
                setInfos(
                  commune.District?.Department?.Region?.Name ?? "",
                  commune.District?.Department?.Name ?? "",
                  commune.District?.Name ?? "",
                  commune.Name ?? ""
                );
                filterDistricts(commune.District?.Name ?? "");
              }}
              onFill={(value: string) => {
                const dis = fltrdDis.find((d) => d.Name === value);
                setInfos(
                  dis?.Department?.Region?.Name ?? "",
                  dis?.Department?.Name ?? "",
                  value,
                  inputCommune
                );
              }}
            />
          </Grid>
        </div>
        <div className="input-title-dep">
          <Grid container spacing={1} rowSpacing={1}>
            {/* <LockIcon
              sx={{ color: "#c3802b", marginTop: ".5rem", fontSize: "1.1rem" }}
            /> */}
            &nbsp;
            <CustomHint
              value={inputDepartment}
              options={fltrdDep.map((d) => d.Name)}
              inputClass="input-search-department"
              placeholder="département"
              onChange={(value: string) => filterDepartments(value)}
              onFocus={() => {
                if (commune !== undefined)
                  setInfos("", "", inputDistrict, inputCommune);
              }}
              onBlur={() => {
                if (commune === undefined) return;
                setInfos(
                  commune.District?.Department?.Region?.Name ?? "",
                  commune.District?.Department?.Name ?? "",
                  commune.District?.Name ?? "",
                  commune.Name ?? ""
                );
                filterDepartments(commune.District?.Department?.Name ?? "");
              }}
              onFill={(value: string) => {
                const dep = fltrdDep.find((d) => d.Name === value);
                setInfos(
                  dep?.Region?.Name ?? "",
                  value,
                  inputDistrict,
                  inputCommune
                );
              }}
            />
          </Grid>
        </div>
        <div className="input-title-reg">
          <Grid container spacing={1} rowSpacing={1}>
            {/* <LockIcon
              sx={{ color: "#c3802b", marginTop: ".5rem", fontSize: "1.1rem" }}
            /> */}
            &nbsp;
            <CustomHint
              value={inputRegion}
              options={fltrdReg.map((r) => r.Name)}
              inputClass="input-search-region"
              placeholder="région"
              onChange={(value: string) => filterRegions(value)}
              onFocus={() => {
                if (commune !== undefined)
                  setInfos("", inputDepartment, inputDistrict, inputCommune);
              }}
              onBlur={() => {
                if (commune === undefined) return;
                setInfos(
                  commune.District?.Department?.Region?.Name ?? "",
                  commune.District?.Department?.Name ?? "",
                  commune.District?.Name ?? "",
                  commune.Name ?? ""
                );
                filterRegions(commune.District?.Department?.Region?.Name ?? "");
              }}
              onFill={(value: string) => setInputRegion(value)}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default FilterSearchPage;
