import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Others from "../../models/Others";

interface OthersState {
    value: Others[];
}

const initialState: OthersState = {
    value: [],
};


export const othersSlice = createSlice({
    name: 'others',
    initialState,
    reducers: {
        setOthers: (state, action: PayloadAction<Others[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setOthers } = othersSlice.actions;
export default othersSlice.reducer;
