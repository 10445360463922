import axios, { AxiosInstance } from 'axios';
import { AppDispatch } from '../../app/store';
import { addLoading, removeLoading } from '../../features/Loading/LoadingReducer';
import ApiResult from './ApiResult';

export const customHttpClient = (
    method: string,
    url: string,
    body: any = undefined,
    instance: AxiosInstance | undefined = undefined,
) => async (dispatch: AppDispatch) => {
    dispatch(addLoading());
    const result: ApiResult = new ApiResult();
    try {
        instance ??= axios.create({ timeout: 30000 });
        await instance({
            method,
            url,
            data: body,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => {
            result.success = true;
            result.code = res.status;
            result.data = res.data;
            result.message = res.statusText;
        }).catch(err => {
            result.code = err.response.status;
            result.data = err.response.data;
            result.message = err.response.statusText;
        });
    } catch (error: any) {
        result.message = error.message;
    }
    dispatch(removeLoading());
    return result;
}

export const httpClient = async (
    method: string,
    url: string,
    body: any = undefined,
    instance: AxiosInstance | undefined = undefined,
) => async (dispacth: AppDispatch) => {
    try {
        dispacth(addLoading());
        instance ??= axios.create({ timeout: 30000 });
        const result = await instance({
            method,
            url,
            data: body,
        });
        dispacth(removeLoading());
        return result;
    } catch (error) {
        dispacth(removeLoading());
        throw error;
    }
}

class customAxios {
    private instance: AxiosInstance;
    constructor() {
        this.instance = axios.create({
            // baseURL: 'http://localhost:5079/api/v1',
            baseURL: 'https://eqt-france.fr/api/v1/',
            timeout: 30000,
        });
        const token = localStorage.getItem('eqtauthToken');
        if (token !== null)
            this.instance.defaults.headers.common['Authorization'] = token;
    }
    public setToken = (token: string) => {
        const auth = `Bearer ${token}`
        localStorage.setItem('eqtauthToken', auth);
        if (this.instance.defaults.headers.common['Authorization'] !== auth)
            this.instance.defaults.headers.common['Authorization'] = auth
    }
    public removeToken = () => {
        delete this.instance.defaults.headers.common['Authorization'];
        localStorage.removeItem('eqtauthToken');
    }

    public newGet = (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch): Promise<ApiResult> =>
            await dispatch(customHttpClient('GET', url, body, this.instance));

    public newPost = (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch): Promise<ApiResult> =>
            await dispatch(customHttpClient('POST', url, body, this.instance))

    public newPut = (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch): Promise<ApiResult> =>
            await dispatch(customHttpClient('PUT', url, body, this.instance))

    public newDelete = (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch): Promise<ApiResult> =>
            await dispatch(customHttpClient('DELETE', url, body, this.instance))

    public get = async (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch) => await dispatch(await httpClient('GET', url, body, this.instance))

    public post = async (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch) => await dispatch(await httpClient('POST', url, body, this.instance))

    public put = async (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch) => await dispatch(await httpClient('PUT', url, body, this.instance))

    public delete = async (url: string, body: any = undefined) =>
        async (dispatch: AppDispatch) => await dispatch(await httpClient('DELETE', url, body, this.instance))
}

const http = new customAxios();
export default http;