// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-product{
    border: 1px solid var( --gold) !important;
    border-radius: 50% !important;
    text-align: center !important;
}
.number-product-offre{
    color: var(--gold) !important;
}
.com-offers{
    font-weight: 100;
}

`, "",{"version":3,"sources":["webpack://./src/components/styles/offers.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,6BAA6B;IAC7B,6BAA6B;AACjC;AACA;IACI,6BAA6B;AACjC;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".number-product{\n    border: 1px solid var( --gold) !important;\n    border-radius: 50% !important;\n    text-align: center !important;\n}\n.number-product-offre{\n    color: var(--gold) !important;\n}\n.com-offers{\n    font-weight: 100;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
