import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import AnnouncementOffer  from "../../models/AnnouncementOffer";

interface OfferState {
    value: AnnouncementOffer[];
}

const initialState: OfferState = {
    value: [],
};

export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        setOffers: (state, action: PayloadAction<AnnouncementOffer[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setOffers } = offerSlice.actions;
export default offerSlice.reducer;
