import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../app/hooks";
import Commune from "../../models/Commune";

export interface UserInfos {
    communeSelected: Commune | undefined,
}

const initialState: UserInfos = {
    communeSelected: undefined,
};


export const userInfosSlice = createSlice({
    name: 'userInfos',
    initialState,
    reducers: {
        setCommuneSelected: (state, action: PayloadAction<Commune>) => {
            state.communeSelected = action.payload;
        },
    },
});


export const { setCommuneSelected } = userInfosSlice.actions;
export default userInfosSlice.reducer;
export const UserInfosSelector = () => useAppSelector((state) => state.userInfos);