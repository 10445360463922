import React, { useEffect } from "react";
import { Grid, LinearProgress } from "@mui/material";
import CommuneContainer from "./CommuneContainer";
import { CommuneSelector } from "../features/Commune/CommuneReducer";
import FilterSearchPage from "../components/FilterSearchPage";
import { IsLoadingSelector } from "../features/Loading/LoadingReducer";
import "./styles/home.css";
import DarkMode from "../components/DarkMode";
import useIsMobile from "../features/Responsive/useIsMobile";
import { useAppDispatch } from "../app/hooks";
import { getCommunesByRegionId } from "../features/Commune/CommuneActions";

const Home = () => {
  const [lockScroll, setLockScroll] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isLoading = IsLoadingSelector();
  const communes = CommuneSelector();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const getActualCommuneIndex = () => {
    if (containerRef.current === null) return 0;
    const scrollTop = containerRef.current.scrollTop;
    const children = containerRef.current.children;
    let nearestCommuneIndex = 0;
    let nearestCommuneDistance = Infinity;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const distance = Math.abs(
        child.getBoundingClientRect().top - scrollTop
      );
      if (distance < nearestCommuneDistance) {
        nearestCommuneDistance = distance;
        nearestCommuneIndex = i;
      }
    }
    return nearestCommuneIndex;
  };

  const loadMoreFunc = (callback: (value: boolean) => void) => {
    dispatch(getCommunesByRegionId(
      communes[communes.length - 1]?.District?.Department?.RegionId ?? 1,
      communes.length - 1
    )).then(() => {
      setTimeout(() => {
        callback(false);
      }, 2000);
    });
  }

  const tmpBool = communes.length > 0;

  useEffect(() => {
    if (communes.length > 0)
      loadMoreFunc((value: boolean) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpBool]);

  useEffect(() => {
    let tmplockScroll = false;
    let tmpLockLoad = false;
    const handleScroll = (e: any) => {
      if (e.deltaX !== 0) {
        return false;
      }
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      if (tmplockScroll) return;
      tmplockScroll = true;
      if (lockScroll) return;
      setLockScroll(true);
      let delta = e.wheelDelta ? e.wheelDelta : -e.detail;

      // get the actual commune index
      let actCommuneIndex = getActualCommuneIndex();
      // if the delta is positive scroll down, otherwise scroll up
      if (delta < 0) {
        // if the actual commune index is not the last one, scroll to the next one
        if (actCommuneIndex < communes.length - 1) {
          actCommuneIndex++;
        }
      } else {
        // if the actual commune index is not the first one, scroll to the previous one
        if (actCommuneIndex > 0) {
          actCommuneIndex--;
        }
      }
      if (!tmpLockLoad && communes.length > 0 && actCommuneIndex === communes.length - 1)
        loadMoreFunc((value: boolean) => tmpLockLoad = value);
      document.querySelector("#commune" + actCommuneIndex)?.scrollIntoView({
        behavior: "smooth",

      });
      // unlock scroll after 1.5s
      setTimeout(() => {
        setLockScroll(false);
        tmplockScroll = false;
      }, 2200);
    };
    window.addEventListener('mousewheel', handleScroll, { passive: false })
    window.addEventListener('DOMMouseScroll', handleScroll, { passive: false })
    return () => {
      window.removeEventListener('mousewheel', handleScroll)
      window.removeEventListener('DOMMouseScroll', handleScroll)
    }
  });

  const className = isMobile ? "mobile-board-container" : "board-container";
  return (
    <div style={{ height: "100%" }}>
      <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 101 }}>
        {isLoading && (
          <LinearProgress sx={{ width: "100%", color: '#c3802b', backgroundColor: '#b26500' }} />
        )}
      </div>
      {(communes === undefined || communes.length === 0) && (
        <Grid
          container
          sx={{
            margin: 0,
            padding: 0,
            height: "100%",
            marginLeft: "1rem",
            width: "100%",

          }}
        >
          <Grid item xs={7} sm={8.5} md={9} >
            <FilterSearchPage commune={undefined} />
          </Grid>
          <Grid item xs={5} sm={3.5} md={3} sx={{ zIndex: 101 }} className="dark-home">
            <DarkMode />
          </Grid>
        </Grid>
      )}
      {communes.length > 0 && (
        <Grid
          container

        >
          <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column' }} id={"homeMainPage"}>
            {communes.map((commune, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={index} className={className} id={"commune" + index} marginBottom={25} position={'relative'}>
                <CommuneContainer commune={commune} />

              </Grid>
            ))}
          </div>
        </Grid>
      )}
    </div>
  );
};

export default Home;
