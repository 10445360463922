// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.p_eqt{
    font-size: 1.1rem !important;
    line-height: 1.1rem;
}

/***tablet***/
@media (min-width:350px) and (max-width: 1024px) {
    p.p_eqt{
      font-size: .9rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/pourquoi_eqt.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA,aAAa;AACb;IACI;MACE;IACF;AACJ","sourcesContent":["p.p_eqt{\n    font-size: 1.1rem !important;\n    line-height: 1.1rem;\n}\n\n/***tablet***/\n@media (min-width:350px) and (max-width: 1024px) {\n    p.p_eqt{\n      font-size: .9rem\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
