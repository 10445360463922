import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../app/hooks";

export enum MessageTypes {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

export interface Message {
    type: MessageTypes;
    text: string;
}

interface MessageState {
    value: Message[];
}

const initialState: MessageState = {
    value: [],
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<Message>) => {
            state.value.push(action.payload);
        },
        removeMessage: (state, action: PayloadAction<number>) => {
            state.value.splice(action.payload, 1);
        },
        removeFrstMessage: (state) => {
            state.value.shift();
        },
    },
});

export const { addMessage, removeMessage, removeFrstMessage } = messageSlice.actions;
export default messageSlice.reducer;
export const MessageSelector = () => useAppSelector((state) => state.message.value);