import useIsMobile from './useIsMobile'
import useIsTablet from './useIsTablet';


function Responsive({ mobile, tablet, desktop }: { mobile: JSX.Element, tablet: JSX.Element, desktop: JSX.Element }) {
  const isMobileDevice = useIsMobile();
  const isTabletDevice = useIsTablet();
  if (!isMobileDevice && !isTabletDevice) return desktop;
  return isMobileDevice ? mobile : tablet;
}

Responsive.defaultProps = {
  mobile: <></>,
  desktop: <></>,
  tablet: <></>,
};
  
export default Responsive;
