import http from "../../api/instance/axios";
import { AppDispatch } from "../../app/store";
import EQTNotes from "../../models/EQTNotes";

export const getBilanByCommuneId = (communeId: number) => async (dispatch: AppDispatch) => {
    try {
        const withBase = true;
        const res = await dispatch(http.newGet(`/Eqt/ByCommuneId/${communeId}?withBase=${withBase}`));
        if (res && res.data)
            return res.data as EQTNotes;
    } catch (error) {
        console.log(error);
    }
}