import ApiResult from "../../api/instance/ApiResult";
import http from "../../api/instance/axios";
import { AppDispatch } from "../../app/store";

export const register = (username: string, email: string, password: string, confirmPassword: string) =>
    async (dispatch: AppDispatch) => {
        const res = await dispatch(
            http.newPost(
                "/Account/Register",
                { "Username": username, "Email": email, "Password": password, "ConfirmPassword": confirmPassword }
            )
        ) as ApiResult;
        return res.success ? null : res.message;
    }

export const login = (username: string, password: string, rememberMe: boolean) =>
    async (dispatch: AppDispatch) => {
        const res = await dispatch(
            http.newPost(
                "/Account/Login",
                { "Username": username, "Password": password, "RemberMe": rememberMe }
            )
        ) as ApiResult;
        if (res.success) {
            if (res.data.token !== null) {
                http.setToken(res.data.token);
                return null;
            }
            return "Invalid token";
        }
        return res.message;
    }

export const logout = (reload: boolean = true) => {
    http.removeToken();
    if (reload)
        window.location.reload();
}