import { Avatar, Grid } from "@mui/material";
import AnnouncementOffer from "../models/AnnouncementOffer";
import "./styles/offers.css";
import useIsMobile from "../features/Responsive/useIsMobile";



interface Props {
  offers: AnnouncementOffer[];
  name: string;
}

const Offers = ({ offers, name }: Props) => {
  const isMobile = useIsMobile();
  if (offers === undefined || offers.length <= 0) return <></>;

  // console.log("offers", offers);


  const finalOffers = offers.slice(0, 6);
  return (
    <Grid
      container
      rowSpacing={-1}
      columnSpacing={-1}
      justifyContent={"left"}
      marginTop={"-4.5rem"}
    >
      {finalOffers.map((offer, index: number) => (
        <Grid item xs={12} sm={5.2} md={4} key={index}>
          <Grid container>
            <Grid
              item
              xs={3}
              sm={2}
              md={2}
              justifyContent={"flex-end"}
              display={"flex"}
              marginTop={0.5}
            >
              <Avatar
                sx={{
                  border: "2px solid var(--gold)",
                  bgcolor: "transparent",
                  color: "var(--gold)",
                  fontWeight: "600",
                  width: isMobile ? 20 : 35,
                  height: isMobile ? 20 : 35,
                }}
              >
                {index + 1}
              </Avatar>
              &nbsp;&nbsp;
            </Grid>
            <Grid item xs={9} sm={10} md={9}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display={"flex"}
                      marginTop={-1}
                    >
                      <h3>
                        {offer.Title} -{" "}
                        <span className="com-offers">{name}</span>
                      </h3>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      textAlign={"justify"}
                      display={"flex"}
                      marginTop={-1.8}
                    >
                      {offer.Description.slice(0, 60) + "..."}&nbsp;&nbsp;
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Offers;
