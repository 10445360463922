import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Accommodation from "../../models/Accommodation";

interface AccomodationState {
    value: Accommodation[];
}

const initialState:AccomodationState = {
    value: [],
};

export const accomodationSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        setOffers: (state, action: PayloadAction<Accommodation[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setOffers } = accomodationSlice.actions;
export default accomodationSlice.reducer;