import { CardContent, CardMedia, Paper, Typography, Grid } from "@mui/material";
import Projects from "../models/Project";
import CustomSlider from "./customs/CustomSlider";

const ProjectCard = ({
  projects,
  name,
}: {
  projects: Projects[];
  name: string;
}) => {
  const actProjects = [];
  if (projects !== undefined) actProjects.push(...projects);
  if (actProjects.length === 0)
    actProjects.push({
      Title: "Aucun projet",
      Description: "Désolé pas de description pour le moment, n'hésitez à revenir plus tard.",
      ImageUrl:
        "https://images.unsplash.com/photo-1471477464302-eb430383ff16?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDl8fGQlQzMlQTljb3V2ZXJ0ZSUyMGZyYW5jZSp8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
      Id: 0,
      Nature: "",
      Api: "",
      CommuneId: 0,
      Commune: null,
    });
  return (
    <div id="project" >
      <CustomSlider
        objects={actProjects.map((project, index) => (
          <Paper elevation={4} key={index}>
            <h2 style={{ textAlign: "left", marginTop: "-2.5rem",marginLeft: '.8rem',marginBottom: '1.5rem' }}>projets</h2>
            <CardContent>
              <Typography component="h2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} display={"flex"}>
                    <h4 style={{ textAlign: "left", marginTop: "-1.5rem" }}>
                      {project.Title} -{" "}
                      <span className="com-offers">{name}</span>
                    </h4>
                  </Grid>
                </Grid>
                <p style={{ textAlign: "justify", marginTop: "-1.5rem"}}>
                  {project.Description.slice(0, 100)}...
                </p>
                <p style={{ textAlign: "justify" }}>
                  {project.Nature}
                  <span style={{color: "var(--gold)"}}>Adresse exacte du projet</span>
                  <br />
                  Budget en €<br />
                  Date limite de dépôt
                </p>
              </Typography>
              <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body2">
                <CardMedia
                  component="img"
                  src={"https://images.unsplash.com/photo-1471477464302-eb430383ff16?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDl8fGQlQzMlQTljb3V2ZXJ0ZSUyMGZyYW5jZSp8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"}
                  height="510"
                  alt={project.Title}
                  
                  sx={{
                    borderRadius: ".3rem",
                    // width: 420,
                    // height: 480,
                    marginTop: "1rem",
                    
                  }}
                />
            </Typography>
              </Grid>
            </CardContent>
          </Paper>
        ))}
      />
    </div>
  );
};

export default ProjectCard;
