import { Card } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Events from "../models/Events";
import dayjs from 'dayjs';

const CalendarCard = ({ events }: { events: Events[] }) => {
  // Créez une liste d'événements à partir des données fournies
  const eventsList = events.map((event) => ({
    title: event.Title,
    date: new Date(event.Date),
    description: event.Description,
  }));

  // Si la liste d'événements est vide, ajoutez un événement fictif
  if (eventsList.length === 0) {
    eventsList.push({
      title: "",
      date: new Date(),
      description: "",
    });
  }

  return (
    <Card sx={{ borderRadius: ".5rem", width: "99%", height: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={{ marginTop: "2rem", height: "100%" }}
            slots={(props: any) => {
                const day = dayjs(props.date);
                const isToday = dayjs().isSame(day, 'day');
                const isEvent = eventsList.some((event) => day.isSame(event.date, 'day'));
                return { day, isToday, isEvent };
            }}
        />
      </LocalizationProvider>
    </Card>
  );
};

export default CalendarCard;