import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import "./App.css";

function App() {
    const location = useLocation();
    return (
        <>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/cgadmin" element={<Admin />} />
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Routes>
        </>
    );
}

export default App;
