import { Grid, Switch } from "@mui/material";
import { toggleDarkMode } from "../features/darkMode/DarkModeReducer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import "./styles/darkMode.css";
import { useEffect } from "react";

const DarkMode = () => {
  const isDarkMode = useAppSelector((state) => state.darkMode.value);
  const dispatch = useAppDispatch();
  const handleDarkModeToggle = () => {
    dispatch(toggleDarkMode());
  };

//   const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

  useEffect(() => {
    const body = document.body;
    if (isDarkMode) {
      body.classList.add("dark");
      body.classList.remove("light");
    } else {
      body.classList.add("light");
      body.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      marginLeft={"-5rem"}
      marginTop={"2rem"}
      style={{position: 'sticky',display:'flex',flexWrap: 'nowrap', left: 0, zIndex: 99}}
    >
      <LightModeIcon />
      <Switch
        checked={isDarkMode}
        onChange={handleDarkModeToggle}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <DarkModeIcon />
      &nbsp;
    </Grid>
  );
};

export default DarkMode;
