import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import DarkModeReducer from '../features/darkMode/DarkModeReducer';
import AccomodationReducer from '../features/Accomodation/AccomodationReducer';
import CommuneReducer from '../features/Commune/CommuneReducer';
import DistrictReducer from '../features/District/DistrictReducer';
import RegionReducer from '../features/Region/RegionReducer';
import TourismReducer from '../features/Tourism/TourismReducer';
import OthersReducer from '../features/Others/OthersReducer';
import RestaurantsReducer from '../features/Restaurants/RestaurantsReducer';
import FindingReducer from '../features/Finding/FindingReducer';
import OffersReducer from '../features/Offers/OffersReducer';
import ProjectReducer from '../features/Project/ProjectReducer';
import BilanReducer from '../features/Bilan/BilanReducer';
import EventsReducer from '../features/Events/EventsReducer';
import DepartmentReducer from '../features/Department/DepartmentReducer';
import UserReducer from '../features/Users/UsersReducer';
import LoadingReducer from '../features/Loading/LoadingReducer';
import MessageReducer from '../features/Message/MessageReducer';

export const store = configureStore({
    reducer: {
        loading: LoadingReducer,
        darkMode: DarkModeReducer,
        offers: OffersReducer,
        accomodation: AccomodationReducer,
        department: DepartmentReducer,
        commune: CommuneReducer,
        district: DistrictReducer,
        region: RegionReducer,
        tourism: TourismReducer,
        others: OthersReducer,
        restaurants: RestaurantsReducer,
        finding: FindingReducer,
        project: ProjectReducer,
        bilan: BilanReducer,
        events: EventsReducer,
        userInfos: UserReducer,
        message: MessageReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;