import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Finding from "../../models/Finding";

interface FindingState {
    value: Finding[];
}


const initialState: FindingState = {
    value: [],
};


export const findingSlice = createSlice({
    name: 'finding',
    initialState,
    reducers: {
        setFindings: (state, action: PayloadAction<Finding[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setFindings } = findingSlice.actions;
export default findingSlice.reducer;