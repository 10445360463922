import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Project from "../../models/Project";
import { useAppSelector } from "../../app/hooks";

interface ProjectState {
    value: Project[];
}

const initialState: ProjectState = {
    value: [],
};


export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjects: (state, action: PayloadAction<Project[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setProjects } = projectSlice.actions;
export default projectSlice.reducer;
export const ProjetsSelector = () => useAppSelector((state) => state.project.value);
