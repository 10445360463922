import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import District from "../../models/District";
import { useAppSelector } from "../../app/hooks";

interface DistrictState {
    value: District[];
    searchValue: District[];
}

const initialState: DistrictState = {
    value: [],
    searchValue: [],
};


export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {
        setDistricts: (state, action: PayloadAction<District[]>) => {
            state.value = action.payload;
        },
        setSearchDistricts: (state, action: PayloadAction<District[]>) => {
            state.searchValue = action.payload;
        }
    },
});


export const { setDistricts, setSearchDistricts } = districtSlice.actions;
export default districtSlice.reducer;
export const DistrictSelector = () => useAppSelector((state: any) => state.district.value);
export const SearchDistrictSelector = () => useAppSelector((state: any) => state.district.searchValue);