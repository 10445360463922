import { AppDispatch } from "../../app/store";
import http from "../../api/instance/axios";
import Department from "../../models/Department";

export const getSearchDepartments = (region: string, department: string) => async (dispatch: AppDispatch) => {
    try {
        let request = "Department/Search?";
        if (region && region.length > 0)
            request += `region=${region}&`;
        if (department && department.length > 0)
            request += `department=${department}`;
        const res = await dispatch(http.newGet(request));
        if (!res || !res.data)
            return [];
        return res.data as Department[];
    } catch (error) {
        console.log(error);
        return [];
    }
}