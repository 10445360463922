import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Restaurants from "../models/Restaurants";
import Accommodation from "../models/Accommodation";

interface Props {
  accommodations: Accommodation[];
  restaurants: Restaurants[];
}

const CateringCard = ({ accommodations, restaurants }: Props) => {
  const menuPriceRanges: any = [
    { min: 0, max: 100, accomCount: 0, restCount: 0 },
    { min: 100, max: 150, accomCount: 0, restCount: 0 },
    { min: 150, max: 200, accomCount: 0, restCount: 0 },
    { min: 200, max: 250, accomCount: 0, restCount: 0 },
    { min: 250, max: 300, accomCount: 0, restCount: 0 },
  ];

  for (const range of menuPriceRanges) {
    const accoms = accommodations?.filter(
      (accom) => accom.PriceMin <= range.max && accom.PriceMax > range.min
    );
    const rests = restaurants?.filter(
      (rest) => rest.PriceMin <= range.max && rest.PriceMax > range.min
    );
    range.accomCount = accoms?.reduce(
      (total, accom) =>
        total +
        accom.NbrHotels +
        accom.NbrAuberges +
        accom.NbrCampings +
        accom.NbrOtherAccommodations,
      0
    );
    range.restCount = rests?.length;
  }

  return (
    <Card sx={{ height: "100%", borderRadius: ".5rem",padding: 'auto' }}>
      <h2
        style={{ textAlign: "left", marginLeft: "1rem", marginTop: "1rem" }}
      >
        hébergements & restauration
      </h2>
      {menuPriceRanges.map((range: any, index: number) => (
        <CardContent key={index} sx={{ marginTop: "-1rem" }}>
          <Grid container columnSpacing={1}>
            <Grid item xs={10} sm={10} md={10}>
              <Card
                sx={{
                  borderRadius: "3rem",
                  border:
                    range.accomCount > 0
                      ? "1px solid orange"
                      : "1px solid grey",
                  margin: "0px auto 0px auto",
                  textAlign: "center",
                  color: range.accomCount > 0 ? "orange" : "grey",
                  width: "60%",
                  padding: "2px",
                  backgroundColor: "var(--white)",
                }}
              >
                <Typography component="div">
                  <span style={{fontSize: '.88rem'}}>
                    {range.min}€ - {range.max}€ /nuit
                  </span>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Avatar
                sx={{
                  border: range.accomCount
                    ? "2px solid var(--gold)"
                    : "2px solid var(--gray)",
                  bgcolor: "transparent",
                  color: range.accomCount ? "var(--gold)" : "var(--gray)",
                  fontWeight: "600",
                  width: "25px",
                  height: "25px",
                  marginLeft: "-2.5rem",
                }}
              >
                {range.accomCount > 0 ? range.accomCount : "0"}
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      ))}
      <Divider
        variant="middle"
        style={{ marginBottom: ".7rem", marginTop: ".7rem" }}
      />
      {menuPriceRanges.map((range: any, index: number) => (
        <CardContent key={index} sx={{ marginBottom: "-1rem" }}>
          <Grid container columnSpacing={1}>
            <Grid item xs={10} sm={10} md={10}>
              <Card
                sx={{
                  borderRadius: "3rem",
                  border:
                    range.restCount > 0 ? "1px solid orange" : "1px solid grey",
                  margin: "0px auto 0px auto",
                  textAlign: "center",
                  color: range.restCount > 0 ? "orange" : "grey",
                  width: "60%",
                  backgroundColor: "var(--white)",
                  padding: "2px",
                }}
              >
                <Typography component="div">
                  <span style={{fontSize: '.78rem'}}>
                    {range.min}€ - {range.max}€ /menu
                  </span>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <Avatar
                sx={{
                  border: range.restCount
                    ? "2px solid var(--gold)"
                    : "2px solid var(--gray)",
                  bgcolor: "transparent",
                  color: range.restCount ? "var(--gold)" : "var(--gray)",
                  fontWeight: "600",
                  width: "25px",
                  height: "25px",
                  marginLeft: "-2.5rem",
                }}
              >
                {range.restCount > 0 ? range.restCount : "0"}
              </Avatar>
              {/* <Badge
                badgeContent={range.restCount > 0 ? range.restCount : "0"}
                color={range.restCount ? "success" : "secondary"}
                style={{marginLeft: '-2rem'}}
              /> */}
            </Grid>
          </Grid>
        </CardContent>
      ))}
    </Card>
  );
};

export default CateringCard;
