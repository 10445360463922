// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bilan-content{
    width: 100% !important;
    margin: auto !important;
    /* margin-left: -30rem !important; */
}
/* @media (min-width: 350px) and (max-width: 801px) {
    .bilan-content{
        margin-left: 8.5rem !important;
    }
} */

@media (min-width:950px) and (max-width: 1280px) {
   .bilan-content{
        margin-left: -33.5rem !important;
   }
}

@media (min-width: 1281px) and (max-width: 1480px) {
    .bilan-content{
        margin-left: -33.5rem !important;
    }

}

@media (min-width: 1481px) and (max-width: 1980px) {
    .bilan-content{
        margin-left: -34rem !important;
    }
}

@media (min-width: 1981px) and (max-width: 2880px) {
    .bilan-content{
        margin-left: -34rem !important;
    }
}


/*********** tablette ***********/
@media (min-width: 692px) and (max-width: 949px) {
    .bilan-content{
        margin-left: -.5rem !important;
    }

}





`, "",{"version":3,"sources":["webpack://./src/pages/styles/commune-container.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,oCAAoC;AACxC;AACA;;;;GAIG;;AAEH;GACG;QACK,gCAAgC;GACrC;AACH;;AAEA;IACI;QACI,gCAAgC;IACpC;;AAEJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;;AAGA,iCAAiC;AACjC;IACI;QACI,8BAA8B;IAClC;;AAEJ","sourcesContent":[".bilan-content{\n    width: 100% !important;\n    margin: auto !important;\n    /* margin-left: -30rem !important; */\n}\n/* @media (min-width: 350px) and (max-width: 801px) {\n    .bilan-content{\n        margin-left: 8.5rem !important;\n    }\n} */\n\n@media (min-width:950px) and (max-width: 1280px) {\n   .bilan-content{\n        margin-left: -33.5rem !important;\n   }\n}\n\n@media (min-width: 1281px) and (max-width: 1480px) {\n    .bilan-content{\n        margin-left: -33.5rem !important;\n    }\n\n}\n\n@media (min-width: 1481px) and (max-width: 1980px) {\n    .bilan-content{\n        margin-left: -34rem !important;\n    }\n}\n\n@media (min-width: 1981px) and (max-width: 2880px) {\n    .bilan-content{\n        margin-left: -34rem !important;\n    }\n}\n\n\n/*********** tablette ***********/\n@media (min-width: 692px) and (max-width: 949px) {\n    .bilan-content{\n        margin-left: -.5rem !important;\n    }\n\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
