import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from "@mui/icons-material";
import { Card, CardContent, Grid, Slide } from "@mui/material";
import { useState } from "react";

const CustomSlider = ({ objects }: {objects: any[]}) => {
  const [actIndex, setActIndex] = useState<number>(0);
  const handleNext = () => {
    let index = actIndex + 1;
    if (index >= objects.length)
        index = 0;
    setActIndex(index);
  };
  const handlePrevious = () => {
    let index = actIndex - 1;
    if (index < 0)
        index = objects.length - 1;
    setActIndex(index);
  };
  return (
    <Card
      sx={{
        borderRadius: ".5rem",
        height: "100%",
        
      }}
    >
      <Grid container>
        <Grid item xs={10} sm={10} md={10.2}>
        </Grid>
        <Grid item xs={2} sm={2} md={1.8} sx={{marginTop: '2rem'}}>
          <ArrowCircleLeftOutlined onClick={handlePrevious} sx={{ cursor: "pointer",marginLeft: '-.8rem'}} />
          <ArrowCircleRightOutlined onClick={handleNext} sx={{ cursor: "pointer",marginRight: '.5rem'}} />
        </Grid>
      </Grid>
      <CardContent sx={{height: "100%",marginTop: '-1rem',justifyContent:'justify'}}>
        {objects.map((object: any, index: number) => (
          (actIndex === index) && (
            <Slide
              direction="right"
              in={index === actIndex}
              mountOnEnter
              unmountOnExit
              key={index}
              style={{
                textAlign: "center",
                border: "none",
                boxShadow: "none",
              }}
            >
              {object}
            </Slide>
          )
        ))}
      </CardContent>
    </Card>
  );
};

export default CustomSlider;