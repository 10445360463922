import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Department from "../../models/Department";
import { useAppSelector } from "../../app/hooks";

interface DepartmentState {
    value: Department[];
    searchValue: Department[];
}

const initialState: DepartmentState = {
    value: [],
    searchValue: [],
};


export const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        setDepartments: (state, action: PayloadAction<Department[]>) => {
            state.value = action.payload;
        },
        setSearchDepartments: (state, action: PayloadAction<Department[]>) => {
            state.searchValue = action.payload;
        }
    },
});


export const { setDepartments, setSearchDepartments } = departmentSlice.actions;
export default departmentSlice.reducer;
export const DepartmentSelector = () => useAppSelector((state: any) => state.department.value);
export const SearchDepartmentSelector = () => useAppSelector((state: any) => state.department.searchValue);
