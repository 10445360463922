import { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import * as echarts from "echarts";
import "./styles/bilanChart.css";
import { DarkModeSelector } from "../features/darkMode/DarkModeReducer";
import EQTNotes from "../models/EQTNotes";
import CustomSlider from "./customs/CustomSlider";
// import Bilan from "../models/Bilan";
// import Category from "../models/Category";
// import BaseCriteria from '../models/BaseCriteria';
// import Objective from '../models/Objective';
//voir
const Chart = ({
  isDark,
  data,
  id,
}: {
  isDark: boolean;
  data: any;
  id: string;
}) => {

  useEffect(() => {
    const elem = document.getElementById(id);
    const myChart = echarts.init(elem);

    const option = {
      tooltip: {
        trigger: 'axis',
        show: true,
        formatter(params: any) {
            const chartData = Array.isArray(params) ? params[0]! : [params][0]!;
            
            // Récupérer les données nécessaires
            const name = chartData.name;
            const maker = chartData.marker;
            const value = chartData.data.itemStyle.dataType;
            for(let i = 0; i < value.length; i++){
              for(let j = 0; j < value[i].length; j++){
                for(let k = 0; k < value[i][j].length; k++){
                  console.log(value[i][j][k]);
                  return (
                    `<div style="display: flex; justify-content: space-between; align-items: center;">
                      <div style="display: flex; align-items: center;">
                      <span>${maker} - ${name}</span><br/>
                        <div style="width: 10px; height: 10px;border-radius: 50%; margin-right: 5px;"></div>
                      </div>
                      <div>${value[i][j][k]}</div>
                    </div>`
                  )
                }
              }
            }
        },
        borderRadius: 10,
        padding: [12, 15],
        extraCssText: 'backdrop-filter: blur(5px)'
        
      },
      toolbar: {
        show: true,
      },
      animation: false,
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: data.xAxis,
      },
     
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        top: "12%",
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: [
        {
          type: "value",
          min: "0",
          max: "10",
          axisLabel: {
            formatter: (value: number) => {
              return "";
            },
          },
        },
      ],
      itemStyle: {
      borderRadius: 2,
      },
      series: data.series,
      legend: data.legend,
      color: data.color,
    };
    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  });

  return (
    <div style={{ height: "100%", marginTop: "0rem" }}>
      <div id={id} style={{ height: "41rem", width: "100%" }}></div>
      <div style={{ width: "100%", textAlign: "center" }}>{data.name}</div>
    </div>
  );
};

const BilanChart = ({ bilan }: { bilan: EQTNotes | undefined }) => {
  const [datas, setDatas] = useState<any>([]);
  const isDark = DarkModeSelector();

  const colorByNote = (note: number[]) => {
    const decimal = note.map((n) => n.toFixed(2));
    const noteNumber = decimal.map((n) => parseFloat(n));

    return noteNumber.map((n) => {
      if (n <= 1.55) {
        return "#b01e22";
      } else if (n <= 3.55) {
        return "#a96f25";
      } else if (n <= 5.55) {
        return "#a7ad35";
      } else if (n <= 7.55) {
        return "#79b542";
      } else if (n <= 8.55) {
        return "#2fb34a";
      } else if (n <= 10) {
        return "#10a3c0";
      } else {
        return "#0000";
      }
    });
  };

  useEffect(() => {
    if (bilan !== undefined) {
      const newDatas = [];
      const markLine = [];
    
      markLine.push({
        yAxis: 8, //mettre ici la note de l'objectif à atteindre
        label: {
          formatter: () => {
            return "Objectif à atteindre 2050";
          },
          position: "middle",
          color: isDark ? "#fff" : "#000",
          fontSize: 16,
        },
      });
      for (const cat of bilan.Categories) {
        const colors = colorByNote(cat.Families.map((fam) => fam.NoteEQT));
        const data = {
          name: cat.Name,
          animation: false,
          xAxis: cat.Families.map((fam) => fam.Name),
          color: colors,
          series: [
            { 
              type: "bar",
              smooth: true,
              data: cat.Families.map((fam, index) => ({
                  value: fam.NoteEQT,
                  itemStyle: {
                    color: colors[index],
                    borderRadius: 5,
                    dataType: cat.Families.map((fam, index) => fam.Criterias.map((crit) => crit.BaseCriteria?.Objectives.map((obj) => obj.Name))),
              }})),
              markLine: {
                data: markLine,
                label: {
                  distance: [50, 8],
                },
              },
            },
          ],
        };
        newDatas.push(data);
      }
      setDatas(newDatas);
    }
  }, [bilan, isDark]);

  return (
    <Card
      sx={{
        borderRadius: ".5rem",
      }}
    >
      {datas.length === 0 && (
        <CardContent sx={{ height: "100%" }}>
          <h1>Pas de données</h1>
        </CardContent>
      )}
      {datas.length > 0 && (
        <CustomSlider
          objects={datas.map((data: any, index: number) => (
            <div key={index} className="content-chart">
              <h2 style={{ textAlign: "start", marginTop: "-4.5rem" }}>
                Bilan EQT
              </h2>
              <Chart
                isDark={isDark}
                data={data}
                id={index.toString() + (bilan?.CommuneId ?? index * 2 + 1)}
              />
            </div>
          ))}
        />
      )}
    </Card>
  );
};

export default BilanChart;
