import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Tourism from "../../models/Tourism";

interface TourismState {
    value: Tourism[];
}

const initialState: TourismState = {
    value: [],
};


export const tourismSlice = createSlice({
    name: 'tourism',
    initialState,
    reducers: {
        setTourisms: (state, action: PayloadAction<Tourism[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setTourisms } = tourismSlice.actions;
export default tourismSlice.reducer;