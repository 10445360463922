import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { httpClient } from "../api/instance/axios";
import './styles/weather.css';

const Weather = ({communeName}: {communeName: string}) => {
  const [weather, setWeather] = useState<any>(undefined);
  const [temperature, setTemperature] = useState<any>(undefined);
  const dispatch = useAppDispatch();
  const API_key = "41ca73004730d67dfe64d135e32fa41f";

  useEffect(() => {
    const getWeatherInfos = async () => {
      try {
          const commune = await dispatch(await httpClient("GET", `https://api.openweathermap.org/data/2.5/weather?q=${communeName}&units=metric&appid=${API_key}`));
          setWeather(commune.data.weather[0]);  
        //   console.log('weather',commune.data.main.temp) 
          setTemperature(Math.round(commune.data.main.temp));     
      } catch (error) {
          console.log(error);
      }
    }
    if (weather === undefined && temperature === undefined) {
      getWeatherInfos()
    }
  }, [weather,temperature, communeName, dispatch]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        &nbsp;&nbsp;
        {weather === undefined && (
          <div style={{height: "50px", width: "50px"}}></div>
        )}
        {!(weather === undefined) && (
        <div style={{display: 'flex',marginLeft: '1.5rem',marginBottom: '-1.2rem',marginTop: "-1rem"}}>
            <img className="weather-img" src={`http://openweathermap.org/img/wn/${weather.icon}@2x.png`} width="95px" height="95px" alt={weather.description} style={{marginBottom:'1rem'}}></img>
            <span style={{ fontWeight: "bold",marginTop: '2.2rem',fontSize: '1.7rem'}}>{temperature} °C</span>
        </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Weather;
