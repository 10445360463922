import {
  Typography,
  Paper,
  CardMedia,
  Grid,
} from "@mui/material";
import Tourism from "../models/Tourism";
import CustomSlider from "./customs/CustomSlider";

const DiscoverCard = ({tourism} : {tourism: Tourism}) => {
  const discovers = [];
  if (tourism?.Findings !== undefined)
    discovers.push(...tourism.Findings);
  if (discovers.length === 0 || discovers === undefined)
    discovers.push({
      Title: "Aucune découverte",
      Description: "",
      ImageUrl: '06',
      Id: 0,
      Nature: "",
      Api: "",
      TourismId: 0,
      Tourism: null
    });
    

  return (
    <Grid container >
      <Grid item xs={12} sm={12} md={11}>
        <CustomSlider objects={discovers.map((f) => (
          <Paper elevation={4} >
            <h2 style={{textAlign: 'left',marginTop: '-2.5rem'}}>découvrir</h2><br/>
              <h4 style={{textAlign: 'left',marginTop: '-1rem'}}>{f.Title}</h4>
            {/* <Typography sx={{ mb: 1.5 ,textAlign: 'left'}}>
              {f.Description.slice(0, 160)}...
            </Typography> */}
            <Typography variant="body2">
              <CardMedia component="img"  src={`./img/Illustrations/${f.ImageUrl}.jpg`} height="470" image={`./img/Illustrations/${f.ImageUrl}.jpg`} alt="" sx={{borderRadius: '.5rem'}}/>
            </Typography>
          </Paper>
        ))} />
      </Grid>
    </Grid>
  );
};

export default DiscoverCard;
