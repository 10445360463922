import { createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../app/hooks";

interface LoadingState {
    value: Number;
    isLoading: Boolean;
};

const initialState: LoadingState = {
    value: 0,
    isLoading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        addLoading: (state) => {
            state.value = Number(state.value) + 1;
            state.isLoading = true;
        },
        removeLoading: (state) => {
            if (state.isLoading === false)
                return;
            state.value = Number(state.value) - 1;
            if (state.value === 0)
                state.isLoading = false;
        },
    },
});

export const { addLoading, removeLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
export const IsLoadingSelector = () => useAppSelector((state) => state.loading.isLoading);