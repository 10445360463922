import { Grid } from "@mui/material";
import Board from "../components/Board";
import Bilan from "../components/Bilan";
import Commune from "../models/Commune";
import './styles/commune-container.css';

const CommuneContainer = ({ commune }: { commune: Commune }) => {
    
  if (commune === undefined) return <></>;
  return (
    <div>
      <Grid container spacing={-2}>
        <Grid item xs={12} sm={5} md={6} sx={{ display: 'inline-block' }}>
          <Board commune={commune} />
        </Grid>
        <Grid item xs={12} sm={5} md={6} className="bilan-content" sx={{ display: 'inline-block' }}>
        <div style={{height: '110px'}}></div>
          <Bilan commune={commune} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CommuneContainer;
