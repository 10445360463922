import { addCommunes, setCommunes } from "./CommuneReducer";
import { AppDispatch } from "../../app/store";
import http from "../../api/instance/axios";
import Commune from "../../models/Commune";
import { getBilanByCommuneId } from "../Bilan/BilanActions";

export const getSearchCommunes = (region: string, department: string, district: string, commune: string) => async (dispatch: AppDispatch) => {
    try {
        let request = "Commune/Search?";
        if (region && region.length > 0)
            request += `region=${region}&`;
        if (department && department.length > 0)
            request += `department=${department}&`;
        if (district && district.length > 0)
            request += `district=${district}&`;
        if (commune && commune.length > 0)
            request += `commune=${commune}`;
        const res = await dispatch(http.newGet(request));
        if (!res || !res.data)
            return [];
        return res.data as Commune[];
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const getCommuneById = (communeId: number) => async (dispatch: AppDispatch) => {
    try {
        const commune = await dispatch(await http.get(`Commune/${communeId}`));
        dispatch(addCommunes([commune.data]));
    } catch (error) {
        console.log(error);
    }
}

export const getCommunesByRegionId = (regionId: number, offset: number = 0, limit: number = 5) => async (dispatch: AppDispatch) => {
    try {
        const res = await dispatch(await http.get(`Commune/Region/${regionId}?limit=${limit}&offset=${offset}`));
        const communes = res.data as Commune[];
        const communeIds = communes.map(commune => commune.Id);
        dispatch(getCommunesInfos(communeIds));
    } catch (error) {
        console.log(error);
    }
}

export const getCommunesInfos = (communeIds: number[], overWrite: Boolean = false) => async (dispatch: AppDispatch) => {
    try {
        if (communeIds.length === 0)
            return;
        const ids = communeIds.join(",");
        const res = await dispatch(await http.get(`Commune/${ids}`))
        if (res && res.data) {
            const tmp = res.data as Commune[];
            const isUnique = tmp.length === undefined;
            const communes = (isUnique ? [tmp] : tmp) as Commune[];
            const finalCommunes: Commune[] = [];
            for (const commune of communes) {
                const bilan = await dispatch(getBilanByCommuneId(commune.Id));
                if (bilan !== undefined)
                    commune.BilanEqt = bilan;
                finalCommunes.push(commune);
            }
            if (overWrite)
                dispatch(setCommunes(finalCommunes));
            else
                dispatch(addCommunes(finalCommunes));
        }
    } catch (error) {
        console.log(error);
    }
}