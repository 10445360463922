import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Bilan from "../../models/Bilan";

interface BilanState {
    value: Bilan[];
}

const initialState: BilanState = {
    value: [],
};


export const bilanSlice = createSlice({
    name: 'bilan',
    initialState,
    reducers: {
        setBilans: (state, action: PayloadAction<Bilan[]>) => {
            state.value = action.payload;
        },
    },
});


export const { setBilans } = bilanSlice.actions;
export default bilanSlice.reducer;

