class ApiResult {
    success: boolean;
    code: number;
    data: any;
    message: string | null;
    constructor(
        success: boolean = false,
        code: number = 0,
        data: any = null,
        message: string | null = null
    ) {
        this.success = success;
        this.code = code;
        this.data = data;
        this.message = message;
    }
}


export default ApiResult;