import { Grid } from "@mui/material";
import CalendarCard from "./CalendarCard";
import HeadLineCard from "./HeadLineCard";
import Events from "../models/Events";

const CalendarHeadLine = ({events} : {events: Events[]}) => {
  return(
    <Grid container marginLeft={'5px'} columnSpacing={1}>
      <Grid item xs={12} sm={6} md={6}>
        <CalendarCard events={events}/>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <HeadLineCard events={events}/>
      </Grid>
    </Grid>
  );
}

export default CalendarHeadLine;