// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-chart {
    /* width: 100%;
    height: 100%; */
    margin-top: 2rem;
    border: transparent !important;
  }

  @media (min-width: 650px) and (max-width: 949px) {
    .content-chart {
      margin-top: 3rem;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/styles/bilanChart.css"],"names":[],"mappings":"AAAA;IACI;mBACe;IACf,gBAAgB;IAChB,8BAA8B;EAChC;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".content-chart {\n    /* width: 100%;\n    height: 100%; */\n    margin-top: 2rem;\n    border: transparent !important;\n  }\n\n  @media (min-width: 650px) and (max-width: 949px) {\n    .content-chart {\n      margin-top: 3rem;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
