import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";
import BilanChart from "./BilanChart";
import Commune from "../models/Commune";
import Responsive from "../features/Responsive/Responsive";
import PourquoiEQT from "./PourquoiEQT";

const Bilan = ({ commune }: { commune: Commune }) => {
  if (commune === undefined) return <></>;

  return (
    <Responsive
      desktop={
        <Grid container columnSpacing={2} sx={{height: "100%"}} >
          <Grid item  md={3} sx={{marginLeft: '-.15rem'}}>
            <ProjectCard projects={commune.Projects} name={commune.Name}  />
          </Grid>
          <Grid item  md={7}>
            <BilanChart bilan={commune.BilanEqt} />
          </Grid>
          <Grid item md={2} sx={{ display: 'inline-block' }} >
          <PourquoiEQT />
        </Grid>
        </Grid>
      }
      tablet={
        <Grid container columnSpacing={1} rowSpacing={1} sx={{height: "100%"}} >
          <Grid item  sm={3} >
            <ProjectCard projects={commune.Projects} name={commune.Name} />
          </Grid>
          <Grid item  sm={6} >
            <BilanChart bilan={commune.BilanEqt} />
          </Grid>
          <Grid item sm={3} sx={{ display: 'inline-block' }} >
          <PourquoiEQT />
        </Grid>
        </Grid>
      }
      mobile={
        <Grid container columnSpacing={0}>
          <Grid item xs={12} sm={12} md={4}>
            <ProjectCard projects={commune.Projects} name={commune.Name} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <BilanChart bilan={commune.BilanEqt} />
          </Grid>
          <Grid item sm={2} sx={{ display: 'inline-block' }} >
          <PourquoiEQT />
        </Grid>
        </Grid>
      }
    />
  );
};

export default Bilan;
