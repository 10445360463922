import { Card, CardContent, Typography, Paper } from "@mui/material";
import Events from "../models/Events";
import CustomSlider from "./customs/CustomSlider";

const HeadLineCard = ({ events }: { events: Events[] }) => {
  if (!(events?.length > 0))
    return (
      <Card
        sx={{
          borderRadius: ".5rem",
          height: "100%",
          width: "99%",
        }}
      >
        <CardContent sx={{ textAlign: "justify" }}>
          <Typography variant="h4" component="div">
            <h2>Aucun événement</h2>
            <p>Il n'y a pas d'événement pour le moment, revenez plus tard</p>
            <p style={{ textAlign: "left", color: "var(--gold)" }}>#tag</p>
          </Typography>
        </CardContent>
      </Card>
    );
  return (
    <Card
      sx={{
        borderRadius: ".5rem",
        width: "99%",
        height: "100%",
      }}
    >
      <CustomSlider
        objects={events.map((ev) => (
          <Paper elevation={4} style={{ padding: "2px" }}>
            <h2 style={{ textAlign: "left", marginTop: "-2.5rem" }}>
              à la une
            </h2>
            <h4
              style={{
                textAlign: "left",
                color: "var(--gray)",
                marginTop: "2rem",
              }}
            >
              {ev.Title}
            </h4>
            <p style={{ textAlign: "justify", marginTop: "-.5rem" }}>
              {ev.Description}
              <br />
              <br />
              <span style={{ textAlign: "left", color: "var(--gold)" }}>
                {ev.Nature} #tag
              </span>
              <br />
              <span style={{ textAlign: "left", color: "var(--gold)" }}>
                {ev.Nature} #tag
              </span>
            </p>
            <p style={{ textAlign: "left", color: "var(--gold)" }}>#tag</p>
          </Paper>
        ))}
      />
    </Card>
  );
};

export default HeadLineCard;
