import { Grid, Button, Collapse } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Commune from "../models/Commune";

interface Props {
  togglePeopleSpace: () => void;
  isToggle: boolean;
  commune: Commune;
}

const PeopleSpace = ({ togglePeopleSpace, isToggle, commune }: Props) => {
  const shortDescription = commune.Description.substring(0, 400);
  const NumberHabitant = commune.NumberHabitant;

  return (
    <Grid container sx={{marginLeft: '2rem', marginTop: '1.5rem' , paddingRight: '1.5rem'}}>
      <Grid item xs={12} sm={12} md={12}>
        <HomeOutlinedIcon sx={{ color: "#c3802b"}}/>
        &nbsp;&nbsp;
        <FavoriteBorderOutlinedIcon sx={{ color: "#c3802b" }} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} >
        <h3>{NumberHabitant} {NumberHabitant > 1 ? 'habitants' : 'habitant'} </h3>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {isToggle && <>{shortDescription}</>}
        {!isToggle && (
          <Collapse in={!isToggle} sx={{paddingRight: '5rem'}}>
            {commune.Description}
          </Collapse>
        )}
      </Grid>
        <Grid item xs={12} sm={12} md={12}>
        <Button
            sx={{ color: "#c3802b" , border: 'none'}}
          onClick={() => {
            togglePeopleSpace();
          }}
        >
          {!isToggle ? 'Réduire' : 'Voir plus ...'}
        </Button>
        </Grid>
    </Grid>
  );
};

export default PeopleSpace;
