// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catering-card-content{
    margin-left: -1.5rem !important;
}
@media (min-width: 650px) and (max-width: 949px) {
    .catering-card-content{
        margin-left: 1rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/discover_catering.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC;AACA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".catering-card-content{\n    margin-left: -1.5rem !important;\n}\n@media (min-width: 650px) and (max-width: 949px) {\n    .catering-card-content{\n        margin-left: 1rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
