import { AppDispatch } from "../../app/store";
import http from "../../api/instance/axios";
import Region from "../../models/Region";

export const getSearchRegions = (region: string) => async (dispatch: AppDispatch) => {
    try {
        let request = "Region/Search?";
        if (region && region.length > 0)
            request += `region=${region}`;
        const res = await dispatch(http.newGet(request));
        if (!res || !res.data)
            return [];
        return res.data as Region[];
    } catch (error) {
        console.log(error);
        return [];
    }
}