import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Commune from '../../models/Commune';
import { useAppSelector } from "../../app/hooks";


interface CommuneState {
    value: Commune[];
    searchValue: Commune[];
}

const initialState: CommuneState = {
    value: [],
    searchValue: [],
};

export const communeSlice = createSlice({
    name: 'commune',
    initialState,
    reducers: {
        setCommunes: (state, action: PayloadAction<Commune[]>) => {
            state.value = action.payload;
        },
        addCommunes: (state, action: PayloadAction<Commune[]>) => {
            let newCommunes = action.payload;
            let oldCommunes = [...state.value] as Commune[];
            for (const commune of newCommunes) {
                const index = oldCommunes.findIndex((it) => it.Id === commune.Id);
                if (index === -1)
                    oldCommunes.push(commune);
                else
                    oldCommunes[index] = commune;
            }
            state.value = oldCommunes;
        },
        updateCommune: (state, action: PayloadAction<Commune>) => {
            let newCommune = action.payload;
            let oldCommunes = [...state.value] as Commune[];
            const index = oldCommunes.findIndex((it) => it.Id === newCommune.Id);
            if (index !== -1)
                oldCommunes[index] = newCommune;
            state.value = oldCommunes;
        },
        updateCommunes: (state, action: PayloadAction<Commune[]>) => {
            let newCommunes = action.payload;
            let oldCommunes = [...state.value] as Commune[];
            for (const commune of newCommunes) {
                const index = oldCommunes.findIndex((it) => it.Id === commune.Id);
                if (index !== -1)
                    oldCommunes[index] = commune;
            }
            state.value = oldCommunes;
        },
        setSearchCommunes: (state, action: PayloadAction<Commune[]>) => {
            state.searchValue = action.payload;
        },

    },
});



export const { setCommunes, addCommunes, updateCommune, updateCommunes, setSearchCommunes } = communeSlice.actions;
export default communeSlice.reducer;
export const CommuneSelector = () => useAppSelector((state) => state.commune.value);
export const SearchCommuneSelector = () => useAppSelector((state) => state.commune.searchValue);

