import { useEffect, useState } from "react";
import { Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch } from "../app/hooks";
import { AdminCommune, AdminCriteria, AdminEvent, AdminFindingProject, AdminOffers, addCommunes, addCriterias, addEvents, addFindingProjects, addOffers, checkAuth, readExcels } from "../features/Admin/AdminActions";
import { Login } from "../components/UserAuth";
import { logout } from "../features/Users/UserActions";

const Admin = () => {
    const [logged, setLogged] = useState<boolean | null>(null);
    const [criterias, setCriterias] = useState<AdminCriteria[]>([]);
    const [communes, setCommunes] = useState<AdminCommune[]>([]);
    const [events, setEvents] = useState<AdminEvent[]>([]);
    const [findings, setFindings] = useState<AdminFindingProject[]>([]);
    const [projects, setProjects] = useState<AdminFindingProject[]>([]);
    const [offers, setOffers] = useState<AdminOffers[]>([]);
    const dispatch = useAppDispatch();
    const checkAccess = async () => await dispatch(checkAuth());
    // disable eslint because we don't want to check if logged change
    // eslint-disable-next-line
    useEffect(() => {
        if (logged === null) {
            setLogged(false);
            checkAccess().then((res) => {
                setLogged(res);
            });
        }
    });
    if (logged === null)
        return <></>;
    if (logged === false) {
        return <Login />;
    }
    const excelToCriterias = (data: any) => {
        const datas: AdminCriteria[] = [...criterias];
        data.forEach((item: any) => {
            datas.push(
                new AdminCriteria(
                    item["Name"],
                    Number(item["NoteEQT"]),
                    item["FamilyName"],
                    item["CategoryName"],
                    item["CodeCommune"],
                    item["Objectif"]
                )
            );
        });
        setCriterias(datas);
    };
    const excelToCommunes = (data: any) => {
        const datas: AdminCommune[] = [...communes];
        data.forEach((item: any) => {
            datas.push(
                new AdminCommune(
                    String(item["COM"]),
                    item["Nom_Commune"],
                    item["ARR"],
                    item["Nom_Arrondissement"],
                    item["DEP"],
                    item["Nom_Departement"],
                    item["REG"],
                    item["Nom_Region"],
                    item["Ill"],
                    item["Nb_Habitant"],
                    item["Description"],
                )
            );
        });
        setCommunes(datas);
    }
    const excelToEvents = (data: any) => {
        const datas: AdminEvent[] = [...events];
        data.forEach((item: any) => {
            datas.push(
                new AdminEvent(
                    item["REG"],
                    item["DEP"],
                    item["ARR"],
                    item["COM"],
                    item["Titre"],
                    item["Texte"],
                    item["Date"],
                )
            );
        });
        setEvents(datas);
    }
    const excelToFindingsProjects = (data: any, isFindings: boolean) => {
        const datas: AdminFindingProject[] = [];
        if (isFindings)
            datas.push(...findings);
        else
            datas.push(...projects);
        data.forEach((item: any) => {
            datas.push(
                new AdminFindingProject(
                    item["REG"],
                    item["DEP"],
                    item["ARR"],
                    item["COM"],
                    item["Titre"],
                    item["Texte"],
                    item["Photo"],
                )
            );
        });
        if (isFindings)
            setFindings(datas);
        else
            setProjects(datas);
    }
    const excelToFindings = (data: any) => excelToFindingsProjects(data, true);
    const excelToProjects = (data: any) => excelToFindingsProjects(data, false);
    const excelToOffers = (data: any) => {
        const datas: AdminOffers[] = [...offers];
        data.forEach((item: any) => {
            datas.push(
                new AdminOffers(
                    item["REG"],
                    item["DEP"],
                    item["ARR"],
                    item["COM"],
                    item["Titre"],
                    item["Texte"],
                    item["Nature"],
                )
            );
        });
        setOffers(datas);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <h1>Admin</h1>
            </Grid>
            <div style={{ position: "absolute", right: "10px", top: "10px" }}>
                <Button variant="contained" onClick={() => logout()}>
                    Logout
                </Button>
            </div>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToCriterias);
                    }}
                    multiple
                />
                Criterias
                <>
                    {criterias.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create Criterias[] with max 500 elements and loop and send
                                const max = 100;
                                const datas: AdminCriteria[] = [...criterias];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addCriterias(data));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Criterias
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">CodeCommune</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">CategoryName</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">FamilyName</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Name</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">NoteEQT</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Objectif</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {criterias.map((row: AdminCriteria, index: number) => (
                                    <TableRow
                                        key={index}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.CodeCommune}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.CategoryName}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.FamilyName}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Name}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.NoteEQT}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Objectif}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToCommunes);
                    }}
                    multiple
                />
                Communes
                <>
                    {communes.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create communes[] with max 500 elements and loop and send
                                const max = 500;
                                const datas: AdminCommune[] = [...communes];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addCommunes(data));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Communes
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">COM</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nom_Commune</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">ARR</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nom_Arrondissement</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">DEP</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nom_Departement</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">REG</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nom_Region</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Ill</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nb_Habitant</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {communes.map((row: AdminCommune, index: number) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.COM}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nom_Commune}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.ARR}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nom_Arrondissement}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.DEP}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nom_Departement}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.REG}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nom_Region}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Ill}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nb_Habitant}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToEvents);
                    }}
                    multiple
                />
                Events
                <>
                    {events.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create communes[] with max 500 elements and loop and send
                                const max = 500;
                                const datas: AdminEvent[] = [...events];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addEvents(data));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Events
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">REG</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">DEP</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">ARR</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">COM</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Titre</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Texte</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((row: AdminEvent, index: number) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.REG}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.DEP}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.ARR}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.COM}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Titre}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Texte}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Date}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToFindings);
                    }}
                    multiple
                />
                Findings
                <>
                    {findings.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create communes[] with max 500 elements and loop and send
                                const max = 500;
                                const datas: AdminFindingProject[] = [...findings];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addFindingProjects(data, true));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Findings
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">REG</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">DEP</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">ARR</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">COM</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Titre</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Texte</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Photo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {findings.map((row: AdminFindingProject, index: number) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.REG}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.DEP}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.ARR}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.COM}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Titre}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Texte}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Photo}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToProjects);
                    }}
                    multiple
                />
                Projects
                <>
                    {projects.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create communes[] with max 500 elements and loop and send
                                const max = 500;
                                const datas: AdminFindingProject[] = [...projects];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addFindingProjects(data, false));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Projects
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">REG</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">DEP</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">ARR</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">COM</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Titre</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Texte</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Photo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {projects.map((row: AdminFindingProject, index: number) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.REG}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.DEP}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.ARR}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.COM}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Titre}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Texte}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Photo}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <input
                    type="file"
                    onChange={(e: any) => {
                        const files = e.target.files;
                        readExcels(files, excelToOffers);
                    }}
                    multiple
                />
                Offers
                <>
                    {offers.length > 0 && (
                        <Button
                            variant="contained"
                            onClick={async () => {
                                // create communes[] with max 500 elements and loop and send
                                const max = 500;
                                const datas: AdminOffers[] = [...offers];
                                const size = datas.length;
                                const loops = Math.ceil(size / max);
                                const errors = [];
                                for (let i = 0; i < loops; i++) {
                                    const data = datas.slice(i * max, (i + 1) * max);
                                    const error = await dispatch(addOffers(data));
                                    if (error !== null) {
                                        errors.push(error);
                                    }
                                }
                                if (errors.length === 0)
                                    alert("Datas added to database");
                                else
                                    alert("Error while adding data to database:\n" + errors.join("\n"));
                            }}
                        >
                            Add Offers
                        </Button>
                    )}
                    <Divider sx={{ marginTop: "10px" }} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border: 1 }} align="center">REG</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">DEP</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">ARR</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">COM</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Titre</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Texte</TableCell>
                                    <TableCell sx={{ border: 1 }} align="center">Nature</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map((row: AdminOffers, index: number) => (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell sx={{ border: 1 }} align="left">{row.REG}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.DEP}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.ARR}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.COM}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Titre}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Texte}</TableCell>
                                        <TableCell sx={{ border: 1 }} align="left">{row.Nature}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </Grid>
        </Grid>
    );
};

export default Admin;
