import { useState } from "react";

interface CustomHintProps {
  value: string;
  options: string[];
  inputClass: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onFill: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const CustomHint = ({ value, options, inputClass, placeholder, onChange, onFill, onFocus, onBlur }: CustomHintProps) => {
  const [hintEmpty, setHintEmpty] = useState<boolean>(false);
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      onFill(options.length > 0 ? options[0] : value);
    }
  }
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {!hintEmpty && options.length > 0 && (
        <input
          disabled={true}
          placeholder={options[0]}
          className={inputClass}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: -1,
            backgroundColor: 'transparent',
            border: 'none',
            opacity: 0.5,
          }}
        />
      )}
      <input
        value={value}
        onFocus={(_) => {
          if (onFocus !== undefined)
            onFocus()
          setHintEmpty(true);
        }}
        onBlur={(_) => onBlur !== undefined ? onBlur() : null}
        onKeyDown={(e) => onKeyDown(e)}
        onChange={(e) => {
          const actInput = e.target.value;
          setHintEmpty(actInput.length === 0);
          onChange(actInput);
        }}
        placeholder={placeholder}
        className={inputClass}
        style={{ zIndex: 2, backgroundColor: 'transparent', border: 'none' }}
      />
    </div>
  );
}

export default CustomHint;