import { Grid } from "@mui/material";
import './styles/pourquoi_eqt.css';
const PourquoiEQT = () => {
    return(
        <Grid container sx={{textAlign: 'left',marginTop: '.2rem', marginLeft: '5rem' ,width: '100%'}}>
            <Grid item xs={12} sm={12} md={12} textAlign={'left'} >
                <h1>Pourquoi EQT ?</h1>
                <Grid item xs={12} sm={5} md={9} >
                    <p className="p_eqt">
                        Parce que c’est une plateforme numérique gratuite et ouverte à tous, déployée sur l’ensemble du territoire sous la forme d’un site internet et bientôt d’une application disponible sur tous types de supports. <br/>
                        Parce qu’elle permet de mieux comprendre les enjeux et les objectifs de nos territoires. <br/><br/>
                        Parce qu’elle remet au centre l’acteur principal de l’aménagement de nos territoires : l’habitant. EQT vise à guider les actions à réaliser pour un aménagement de nos paysages pertinent, liés aux objectifs internationaux, nationaux et régionaux.<br/><br/>
                        L’ambition de l’outil est simple : aiguiller les actions à mener à toutes les échelles pour atteindre ces différents engagements de France 2030, fit for 55, du pacte vert, etc. <br/><br/>
                        L’outil est dans sa version 1.1.0 et sera développé en 9 phases de développement pour atteindre les objectifs fixés et bien plus encore.
                    </p>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default PourquoiEQT;