import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Region from "../../models/Region";
import { useAppSelector } from "../../app/hooks";

interface RegionState {
    value: Region[];
    searchValue: Region[];
}

const initialState: RegionState = {
    value: [],
    searchValue: [],
};


export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegions: (state, action: PayloadAction<Region[]>) => {
            state.value = action.payload;
        },
        setSearchRegions: (state, action: PayloadAction<Region[]>) => {
            state.searchValue = action.payload;
        }
    },
});


export const { setRegions, setSearchRegions } = regionSlice.actions;
export default regionSlice.reducer;
export const RegionSelector = () => useAppSelector((state: any) => state.region.value);
export const SearchRegionSelector = () => useAppSelector((state: any) => state.region.searchValue) as Region[];
