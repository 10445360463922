import { read, utils } from "xlsx";
import http from "../../api/instance/axios";
import { AppDispatch } from "../../app/store";
import ApiResult from "../../api/instance/ApiResult";
import { login } from "../Users/UserActions";
import { MessageTypes, addMessage } from "../Message/MessageReducer";

export class AdminCriteria {
    Name: string;
    NoteEQT: number;
    FamilyName: string;
    CategoryName: string;
    CodeCommune: string;
    Objectif: string;
    constructor(Name: string, NoteEQT: number, FamilyName: string, CategoryName: string, CodeCommune: string, Objectif: string) {
        this.Name = Name;
        this.NoteEQT = NoteEQT;
        this.FamilyName = FamilyName;
        this.CategoryName = CategoryName;
        this.CodeCommune = CodeCommune;
        this.Objectif = Objectif;
    }
}

export class AdminCommune {
    COM: string;
    Nom_Commune: string;
    ARR: string;
    Nom_Arrondissement: string;
    DEP: string;
    Nom_Departement: string;
    REG: string;
    Nom_Region: string;
    Ill: string;
    Nb_Habitant: number;
    Description: string;
    constructor(COM: string, Nom_Commune: string, ARR: string, Nom_Arrondissement: string, DEP: string, Nom_Departement: string, REG: string, Nom_Region: string, Ill: string, Nb_Habitant: number, Description: string) {
        this.COM = COM;
        this.Nom_Commune = Nom_Commune;
        this.ARR = ARR;
        this.Nom_Arrondissement = Nom_Arrondissement;
        this.DEP = DEP;
        this.Nom_Departement = Nom_Departement;
        this.REG = REG;
        this.Nom_Region = Nom_Region;
        this.Ill = Ill;
        this.Nb_Habitant = Nb_Habitant;
        this.Description = Description;
    }
}

export class AdminEvent {
    REG: string;
    DEP: string;
    ARR: string;
    COM: string;
    Titre: string;
    Texte: string;
    Date: string;
    constructor(REG: string, DEP: string, ARR: string, COM: string, Titre: string, Texte: string, Date: string) {
        this.REG = REG;
        this.DEP = DEP;
        this.ARR = ARR;
        this.COM = COM;
        this.Titre = Titre;
        this.Texte = Texte;
        this.Date = Date;
    }
}

export class AdminFindingProject {
    REG: string;
    DEP: string;
    ARR: string;
    COM: string;
    Titre: string;
    Texte: string;
    Photo: string;
    constructor(REG: string, DEP: string, ARR: string, COM: string, Titre: string, Texte: string, Photo: string) {
        this.REG = REG;
        this.DEP = DEP;
        this.ARR = ARR;
        this.COM = COM;
        this.Titre = Titre;
        this.Texte = Texte;
        this.Photo = Photo;
    }
}

export class AdminOffers {
    REG: string;
    DEP: string;
    ARR: string;
    COM: string;
    Titre: string;
    Texte: string;
    Nature: string;
    constructor(REG: string, DEP: string, ARR: string, COM: string, Titre: string, Texte: string, Nature: string) {
        this.REG = REG;
        this.DEP = DEP;
        this.ARR = ARR;
        this.COM = COM;
        this.Titre = Titre;
        this.Texte = Texte;
        this.Nature = Nature;
    }

}

let tmpData: any = [];

export const readExcels = (files: FileList, callback: (data: any) => void) => {
    for (let i = 0; i < files.length; i++)
        readExcel(files[i], i === files.length - 1, callback);
};

export const readExcel = (file: File, isLast: boolean, callback: (data: any) => void) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    let data: unknown[] = [];
    fileReader.onload = (e: any) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        data = utils.sheet_to_json(ws);
        tmpData.push(...data);
        if (isLast) {
            callback(tmpData);
            tmpData = [];
        }
    }
};

export const addCriterias = (criterias: AdminCriteria[]) =>
    async (dispatch: AppDispatch) => {
        const stringField = criterias.map(({ CodeCommune, ...rest }) => ({
            CodeCommune: String(CodeCommune),
            ...rest,
        }));
        const jsonStr = JSON.stringify(stringField);
        const res = await dispatch(
            http.newPost(
                "/Admin/AddCriterias",
                jsonStr,
            )
        ) as ApiResult;
        if (res.success)
            return null;
        return res.message;
    };

export const addCommunes = (communes: AdminCommune[]) =>
    async (dispatch: AppDispatch) => {
        const stringified = communes.map(({ COM, ARR, DEP, REG, Ill, ...rest }) => ({
            COM: COM === undefined ? "" : String(COM),
            ARR: ARR === undefined ? "" : String(ARR),
            DEP: DEP === undefined ? "" : String(DEP),
            REG: REG === undefined ? "" : String(REG),
            Ill: String(Ill),
            ...rest,
        }));
        const jsonStr = JSON.stringify(stringified);
        const res = await dispatch(
            http.newPost(
                "/Admin/AddCommunes",
                jsonStr
            )
        ) as ApiResult;
        if (res.success)
            return null;
        return res.message;
    };

export const addEvents = (events: AdminEvent[]) =>
    async (dispatch: AppDispatch) => {
        const stringified = events.map(({ COM, ARR, DEP, REG, ...rest }) => ({
            COM: COM === undefined ? "" : String(COM),
            ARR: ARR === undefined ? "" : String(ARR),
            DEP: DEP === undefined ? "" : String(DEP),
            REG: REG === undefined ? "" : String(REG),
            ...rest,
        }));
        const jsonStr = JSON.stringify(stringified);
        const res = await dispatch(
            http.newPost(
                "/Admin/AddEvents",
                jsonStr
            )
        ) as ApiResult;
        if (res.success)
            return null;
        return res.message;
    };

export const addFindingProjects = (findingProjects: AdminFindingProject[], isFindings: boolean) =>
    async (dispatch: AppDispatch) => {
        const stringified = findingProjects.map(({ COM, ARR, DEP, REG, ...rest }) => ({
            COM: COM === undefined ? "" : String(COM),
            ARR: ARR === undefined ? "" : String(ARR),
            DEP: DEP === undefined ? "" : String(DEP),
            REG: REG === undefined ? "" : String(REG),
            ...rest,
        }));
        const jsonStr = JSON.stringify(stringified);
        const endPoint = isFindings ? "/Admin/AddFindings" : "/Admin/AddProjects";
        const res = await dispatch(
            http.newPost(
                endPoint,
                jsonStr
            )
        ) as ApiResult;
        if (res.success)
            return null;
        return res.message;
    }

export const addOffers = (offers: AdminOffers[]) =>
    async (dispatch: AppDispatch) => {
        const stringified = offers.map(({ COM, ARR, DEP, REG, ...rest }) => ({
            COM: COM === undefined ? "" : String(COM),
            ARR: ARR === undefined ? "" : String(ARR),
            DEP: DEP === undefined ? "" : String(DEP),
            REG: REG === undefined ? "" : String(REG),
            ...rest,
        }));
        const jsonStr = JSON.stringify(stringified);
        const res = await dispatch(
            http.newPost(
                "/Admin/AddOffers",
                jsonStr
            )
        ) as ApiResult;
        if (res.success)
            return null;
        return res.message;
    };

export const authAdmin = () => async (dispacth: AppDispatch) => {
    const username = prompt("Username");
    const password = prompt("Password");
    if (username === null || password === null ||
        username === undefined || password === undefined)
        return false;
    return await dispacth(login(username, password, false));
};

export const checkAuth = () => async (dispatch: AppDispatch) => {
    const res = await dispatch(http.newGet("/Admin/CheckAuth")) as ApiResult;
    if (res.success)
        return true;
    dispatch(addMessage({
        type: MessageTypes.ERROR,
        text: res.message ?? "Unknown error",
    }));
    return false;
};