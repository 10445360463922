import { Grid } from "@mui/material"
import DiscoverCard from "./DiscoverCard"
import CateringCard from "./CateringCard"
import Tourism from "../models/Tourism"
import './styles/discover_catering.css'

const DiscoverCatering = ({tourism} : {tourism: Tourism}) => {
  if(tourism === undefined) return <></>;
  return(
    <Grid container>
      <Grid item xs={12} sm={7} md={4.1}>
        <DiscoverCard tourism={tourism} />
      </Grid>
      <Grid item xs={12} sm={4.7} md={2.8} className="catering-card-content" >
        <CateringCard
          accommodations={tourism?.Accommodations}
          restaurants={tourism?.Restaurants}
        />
      </Grid>
    </Grid>
  );
};

export default DiscoverCatering;