import { createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../../app/hooks";

interface DarkModeState {
    value: boolean;
}

const initialState: DarkModeState = {
    value: true,
};

export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.value = !state.value;
        },

    },

});



export const { toggleDarkMode } = darkModeSlice.actions;
export default darkModeSlice.reducer;
export const DarkModeSelector = () => useAppSelector((state) => state.darkMode.value);

