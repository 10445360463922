import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Events from "../../models/Events";
import { useAppSelector } from "../../app/hooks";


interface EventsState {
    value: Events[];
}

const initialState: EventsState = {
    value: [],
};


export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setEvents: (state, action: PayloadAction<Events[]>) => {
            state.value = action.payload;
        },
        addEvents: (state, action: PayloadAction<Events[]>) => {
            let newEvents = action.payload;
            let oldEvents = [...state.value] as Events[];
            for (const events of newEvents) {
                const index = oldEvents.findIndex((it) => it.Id === events.Id);
                if (index === -1)
                    oldEvents.push(events);
                else
                    oldEvents[index] = events;
            }
            state.value = oldEvents;
        },
        updateEvents: (state, action: PayloadAction<Events>) => {
            let newEvents = action.payload;
            let oldEvents = [...state.value] as Events[];
            const index = oldEvents.findIndex((it) => it.Id === newEvents.Id);
            if (index !== -1)
                oldEvents[index] = newEvents;
            state.value = oldEvents;
        },

    },
});


export const { setEvents, addEvents, updateEvents } = eventsSlice.actions;
export default eventsSlice.reducer;
export const useEvents = () => useAppSelector(state => state.events.value);