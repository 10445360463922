// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-mode-container {
    background-color: black; /* Définissez la couleur de fond pour le mode sombre */
    color: white; /* Définissez la couleur du texte pour le mode sombre */
    /* Autres styles pour le mode sombre */
}

/* Light Mode Styles */
.light-mode-container {
    background-color: white; /* Définissez la couleur de fond pour le mode clair */
    color: black; /* Définissez la couleur du texte pour le mode clair */
    /* Autres styles pour le mode clair */
}

body.dark .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: var(--gold) !important;
}

body.dark .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: var(--gold) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/darkMode.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,sDAAsD;IAC/E,YAAY,EAAE,uDAAuD;IACrE,sCAAsC;AAC1C;;AAEA,sBAAsB;AACtB;IACI,uBAAuB,EAAE,qDAAqD;IAC9E,YAAY,EAAE,sDAAsD;IACpE,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,wCAAwC;AAC5C","sourcesContent":[".dark-mode-container {\n    background-color: black; /* Définissez la couleur de fond pour le mode sombre */\n    color: white; /* Définissez la couleur du texte pour le mode sombre */\n    /* Autres styles pour le mode sombre */\n}\n\n/* Light Mode Styles */\n.light-mode-container {\n    background-color: white; /* Définissez la couleur de fond pour le mode clair */\n    color: black; /* Définissez la couleur du texte pour le mode clair */\n    /* Autres styles pour le mode clair */\n}\n\nbody.dark .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {\n    color: var(--gold) !important;\n}\n\nbody.dark .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {\n    background-color: var(--gold) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
