// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-title input{
    font-size: 4rem !important;
    font-weight: bold !important;
    font-family: 'Poppins', sans-serif !important;
    border: none;
    width: 100%;
}

@media (min-width: 692px) and (max-width: 949px) {
    .input-title input{
        font-size: 2.5rem !important;
    }
}
@media (min-width: 949px) and (max-width: 1024px) {
    .input-title input{
        font-size: 3.6rem !important;
    }
}

.mobile-input-title input{
    font-size: 1.8rem !important;
    font-weight: 900;
    border: none;
}
.mobile-input-title input:focus,
.input-title input:focus{
    outline: none;
}
.input-search-commune.dark{
    background-color: var(--black) !important;
    overflow: hidden;
    resize: none  
}
.input-search-district,
.input-search-department,
.input-search-region{
    font-size: .9rem !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight:  500 !important;
    margin-top: .5rem !important;
    margin-left: -1rem;
    border: 0;
    outline: none !important;
    width: 100%;
}
.input-title-district, 
.input-title-dep, 
.input-title-reg {
    margin-left: 1.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/filter-search-page.css"],"names":[],"mappings":";AACA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,6CAA6C;IAC7C,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ;AACA;IACI;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,YAAY;AAChB;AACA;;IAEI,aAAa;AACjB;AACA;IACI,yCAAyC;IACzC,gBAAgB;IAChB;AACJ;AACA;;;IAGI,2BAA2B;IAC3B,6CAA6C;IAC7C,4BAA4B;IAC5B,4BAA4B;IAC5B,kBAAkB;IAClB,SAAS;IACT,wBAAwB;IACxB,WAAW;AACf;AACA;;;IAGI,8BAA8B;AAClC","sourcesContent":["\n.input-title input{\n    font-size: 4rem !important;\n    font-weight: bold !important;\n    font-family: 'Poppins', sans-serif !important;\n    border: none;\n    width: 100%;\n}\n\n@media (min-width: 692px) and (max-width: 949px) {\n    .input-title input{\n        font-size: 2.5rem !important;\n    }\n}\n@media (min-width: 949px) and (max-width: 1024px) {\n    .input-title input{\n        font-size: 3.6rem !important;\n    }\n}\n\n.mobile-input-title input{\n    font-size: 1.8rem !important;\n    font-weight: 900;\n    border: none;\n}\n.mobile-input-title input:focus,\n.input-title input:focus{\n    outline: none;\n}\n.input-search-commune.dark{\n    background-color: var(--black) !important;\n    overflow: hidden;\n    resize: none  \n}\n.input-search-district,\n.input-search-department,\n.input-search-region{\n    font-size: .9rem !important;\n    font-family: 'Poppins', sans-serif !important;\n    font-weight:  500 !important;\n    margin-top: .5rem !important;\n    margin-left: -1rem;\n    border: 0;\n    outline: none !important;\n    width: 100%;\n}\n.input-title-district, \n.input-title-dep, \n.input-title-reg {\n    margin-left: 1.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
