// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-board-container{
    width: 100%;
}
.board-container{
    width: 250rem;
}
/*****tablet*****/
@media (min-width: 650px) and (max-width: 949px) {
    .board-container{
        width: 225rem !important;
    }
    .dark-home{
        margin-top: -1.5rem !important;
    }
}

 
 `, "",{"version":3,"sources":["webpack://./src/pages/styles/home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA,iBAAiB;AACjB;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,8BAA8B;IAClC;AACJ","sourcesContent":[".mobile-board-container{\n    width: 100%;\n}\n.board-container{\n    width: 250rem;\n}\n/*****tablet*****/\n@media (min-width: 650px) and (max-width: 949px) {\n    .board-container{\n        width: 225rem !important;\n    }\n    .dark-home{\n        margin-top: -1.5rem !important;\n    }\n}\n\n \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
