// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 650px) and (max-width: 1024px) {
    .dark-content{
        margin-top: -1.5rem !important;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/styles/board.css"],"names":[],"mappings":"AAAA;IACI;QACI,8BAA8B;IAClC;AACJ","sourcesContent":["@media (min-width: 650px) and (max-width: 1024px) {\n    .dark-content{\n        margin-top: -1.5rem !important;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
